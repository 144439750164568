@media only screen and (max-width: 768px) {
  .header-logo {
    margin: auto;
    width: 25%;
    display: block;
    line-height: 44px;
  }
  .header .user-menu {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 426px) {
  .header-logo {
    margin: auto;
    width: 50%;
    display: block;
    line-height: 44px;
  }
}
