.scroll-sidebar {
  overflow-y: scroll; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides the horizontal scrollbar */
}

.scroll-sidebar::-webkit-scrollbar {
  width: 8px; /* Sets the width of the vertical scrollbar */
}

.scroll-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Style for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar */
}

.scroll-sidebar::-webkit-scrollbar-track {
  background-color: transparent; /* Scrollbar track background */
}
