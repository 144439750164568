/* :where(.css-dev-only-do-not-override-1rqnfsa).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 0 50px;
} */
:where(.css-dev-only-do-not-override-1rqnfsa).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.card .card-body {
  padding: 35px !important;
}
.account-page .main-wrapper .account-content .account-logo img {
  width: 100%;
}
:where(.css-1rqnfsa).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
:where(.css-1rqnfsa).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #fdfdfd !important;
}
.ant-select-focused:where(.css-1rqnfsa).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #f3f3f3 !important;
  box-shadow: none !important;
  outline: 0;
}
.table td {
  word-wrap: break-word; /* Force long words to break and wrap */
  white-space: normal; /* Allow text to wrap to the next line */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
  text-wrap: pretty;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.feather-loader {
  animation: spin 2s linear infinite;
}
.login_page-logo img {
  width: 100%;
  height: 120px;
  margin-bottom: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to vertically center */
  text-align: center;
}

.custom-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.loading-text {
  font-size: 18px;
  color: #555;
  font-weight: 500;
  margin-top: 10px;
}
.feather-loaders {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation to spin the loader */
}

/* Add this to your CSS file */
.dropdown-menu {
  width: 300px; /* Set a width to control how wide the dropdown should be */
}

.dropdown-item {
  white-space: normal; /* Allow wrapping */
  word-wrap: break-word; /* Break the text if it's too long */
  overflow-wrap: break-word; /* Ensure long words are broken */
}
.dropdown-toggle {
  max-width: 250px; /* Adjust the max width of the button */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
  display: inline-block; /* Ensure the button behaves like a block element */
  vertical-align: middle;
}
